<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="login">
        <div class="cst-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="cst-logo"
          />
        </div>
        <b-field label="">
          <b-input
            v-model="email"
            placeholder="Email"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <div class="mt-1 has-text-left">
          You will receive an email with the password reset link.
        </div>

        <div class="field mt-5">
          <div class="control">
            <b-button
              type="is-primary is-fullwidth mb-3"
              @click="submitForm"
              :loading="isLoading"
            >
              Reset Password</b-button
            >
            <b-button
              type="is-primary is-light is-fullwidth mb-3"
              @click="LogIn"
              >Back to Login</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      url: "/auth/users/reset_password/",
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    async performReset() {
      await axios
        .post(this.url, {
          email: this.email,
        })
        .then((response) => {
          if (response.status === 204) {
            this.error = false;
            this.errorMessage = "";
            this.$buefy.toast.open({
              message: "Password reset link sent to your email",
              type: "is-success",
              queue: false,
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        });
    },
    formValid() {
      return this.email.length > 0;
    },
    submitForm() {
      if (this.formValid()) {
        this.$store.dispatch("setLoading", true);
        this.error = false;
        this.errorMessage = "";
        this.performReset().then(() => {
          if (this.error) {
            this.$buefy.toast.open({
              message: "Something went wrong. Please try again.",
              type: "is-danger",
            });
            this.$store.dispatch("setLoading", false);
          } else {
            setTimeout(() => {
              this.$store.dispatch("setLoading", false);
              this.$router.push({
                name: "UserLogin",
              });
            }, 2000);
          }
        });
      } else {
        this.error = true;
        this.errorMessage = "Please fill in all the required fields.";
        this.$buefy.toast.open({
          message: this.errorMessage,
          type: "is-danger",
        });
      }
    },
    LogIn() {
      this.$router.push({ name: "UserLogin" });
    },
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 10%;
}
</style>
